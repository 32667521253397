



import { Component, Vue } from 'vue-property-decorator';
import { WPPost, WPCategories } from '../../types/typescript-axios/api';
import NotFound from './NotFound.vue';
import VueRouter from 'vue-router';
import axios, {AxiosResponse, AxiosError} from 'axios';
import store from '../store';
import Pagenation from '../components/contents/pangenation/Pangenation.vue';
import InfoBanner from '../components/contents/info/InfoBanner.vue';
import InfoListTag from '../components/contents/info/InfoListTag.vue';
import Error from '../components/contents/Error.vue';
import Breadcrumb from '@/components/contents/breadcrumb/Breadcrumb.vue';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteUpdate',
]);

const maxLength: number = 25;

async function getBannerDatas() {
  const url: string = '/wp-json/wp/v2/banner';
  const params = {
    per_page: 4,
  };
  const res = await axios.get<AxiosResponse>(url, {params})
  .then((response) => {
    return response.data;
  })
  .catch((error) => {
    return [];
  });
  return res;
}

async function getListDatas(to: VueRouter) {
  const url: string = '/wp-json/moc/info';
  const $to: any = to;

  const params: any = {
    per_page: maxLength,
    order: 'desc',
    orderby: 'date',
    // offset: 5,
  };

  if (typeof $to.query.p !== 'undefined') {
    params.page = $to.query.p;
    // params.offset = ($to.query.p - 1) * maxLength;
  }

  const res = await new Promise((resolve, reject) => {
    axios.get<AxiosResponse>(url, {
      params,
    })
    .then( (response) => {
      resolve(response);
    })
    .catch( (e: AxiosError) => {
      reject(e);
    });
  });
  return res;
}

// async function getCategories(params:type) {

// }
async function getDatas(to: VueRouter) {
  const banners = await getBannerDatas();
  const categoryUrl: string = '/wp-json/wp/v2/info_cat';
  const categoryRes = await axios.get<AxiosResponse>(categoryUrl);
  const categoriesRes: any = categoryRes.data;
  const $to: any = to;

  const aryCheck = categoriesRes.filter((value: any) => {
    if (value.slug === $to.query.category && value.count > 0 && value.parent === 0) {
      return true;
    }
  });
  const categories = categoriesRes.filter((value: any) => {
    if (value.count > 0 && value.parent === 0) {
      return true;
    }
  });

  try {
    const resListData: any = await getListDatas(to);
    const total = resListData.headers['x-wp-total'];
    const status = resListData.status;
    const contents: any = resListData.data;
    return {
      status,
      contents,
      categories,
      banners,
      total,
    };
  } catch (e) {
    return {
      status: e.response.status,
    };
  }
}

@Component({
  components: {
    Breadcrumb,
    NotFound,
    Pagenation,
    InfoBanner,
    InfoListTag,
    Error,
  },
})
export default class Info extends Vue {
  private breadcrumb: BreadcrumbArr = [
    {
      title: 'HOME',
      link: '/',
    },
    {
      title: 'お知らせ・キャンペーン一覧',
      link: '',
    },
  ];
  private listLength: number = maxLength;
  private info: any;
  private total: any = '';
  private paramsName: number = 0;
  private link: string = '/info/?p=';
  private contents: WPPost[] = [];
  private banners: WPPost[] = [];
  private categories: WPCategories[] = [];
  private status = 200;
  get queryName(): number {
    if (typeof this.$route.query.p === 'undefined') {
      return 1;
    } else {
      return Number(this.$route.query.p);
    }
  }
  // private notFound: boolean = false;
  public async beforeRouteEnter(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    const $to: any = to;
    const query: any = $to.query;
    const p = 'p';
    if (data.status === 200) {
      next((vm: {
        contents: any,
        banners: WPPost,
        categories: WPCategories,
        paramsName: number,
        status: number,
        total: any,
        btnMaxNum: number,
      }) => {
        vm.status = data.status;
        vm.contents = data.contents;
        vm.banners = data.banners;
        vm.categories = data.categories;
        vm.total = data.total;
      });
    } else {
      next((vm: {status: number}) => {
        vm.status = data.status;
      });
    }
  }
  public async beforeRouteUpdate(to: VueRouter, from: VueRouter, next: any) {
    const data: any = await getDatas(to);
    this.status = data.status;
    if (data.status === 200) {
      const $to: any = to;
      this.total = data.total;
      this.paramsName = Number($to.query.p);
      this.contents = data.contents;
      this.categories = data.categories;
    }
    next();
  }
}
