




import { Component, Vue } from 'vue-property-decorator';
import Error from '../components/contents/Error.vue';
import VueRouter from 'vue-router';
import store from '../store';

Component.registerHooks([
  'beforeRouteLeave',
]);

@Component({
  components: {
    Error,
  },
})
export default class NotFound extends Vue {
  get error() {
    return this.$store.getters['window/isNotFound'];
  }
  private async beforeRouteLeave(to: VueRouter, from: VueRouter, next: any) {
    store.commit('window/setNotFound', 200);
    next();
  }
}
